
import CitySelect from '../components/city-select.vue'
import { getDictLists } from '@/filters/fromDict'
import ApplicationAmount from '../components/application-amount.vue'
// 查询
export const formConfig = (content) => {
  return [
    {
      label: '申请金额(万元)',
      prop: 'capitalSideType',
      tag: ApplicationAmount,
      attrs: {
        placeholder: '请输入申请金额',
        type: 'WY',
        value1: content.queryParas.AmountArr[0],
        value2: content.queryParas.AmountArr[1]
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      on: {
        firstInput (data) {
          content.AmountArrChange(0, data)
        },
        secondInput (data) {
          content.AmountArrChange(1, data)
        }
      }
    },
    {
      label: '所在城市',
      prop: 'addressArr',
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      tag: CitySelect,
      attrs: {
        addressvalue: content.baseQueryParas,
        type: 'add',
        placeholder: '请选择',
        address: getDictLists('ZDZD0013')
      }
      // on: {
      //   change: (data) => {
      //     content.changeCapitalSide(data)
      //   }
      // }
    },
    {
      label: '申请企业',
      prop: 'applyEnterprise',
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '申请经办人',
      prop: 'applyOperator',
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      isHidden: content.fold,
      attrs: {
        maxlength: '20',
        placeholder: '请输入'
      }
    }

  ]
}
// 表格
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '申请金额(万元）',
      prop: 'applyAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '申请期限(月）',
      prop: 'applyTerm',
      'show-overflow-tooltip': true,
      minWidth: '120px'
    },
    {
      label: '预期利率(%）',
      prop: 'expectRate',
      minWidth: '120px',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.expectRate ? content.keepTwoDecimalFull(row.expectRate) : '')
      }
    },
    {
      label: '所在城市',
      prop: 'address',
      minWidth: '120px',
      'show-overflow-tooltip': true
    },
    {
      label: '申请企业',
      prop: 'applyEnterprise',
      minWidth: '120px',
      'show-overflow-tooltip': true,
      align: 'left'

    },
    {
      label: '申请经办人',
      prop: 'applyOperator',
      minWidth: '120px',
      'show-overflow-tooltip': true
    },
    {
      label: '经办人电话',
      prop: 'operatorPhone',
      minWidth: '135px',
      'show-overflow-tooltip': true
    },
    {
      label: '业务经理',
      prop: 'businessManager',
      minWidth: '135px',
      'show-overflow-tooltip': true
    }

  ]
}
