<template>
  <div>
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData"
    >
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        ></base-button>
      </div>
    </base-form>
    <!-- 数据表格 -->
    <div class="main-page-table__header">
      <base-button
        label="导出"
        @click="FileDdownload()"
        icon="iconfont icondaochu"
      ></base-button>
    </div>
    <base-table
      class="main-page-table with-add"
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        stripe: true,
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :webPage="false"
      :dataSource.sync="tableData"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
    </base-table>
  </div>
</template>

<script>
import { downFile } from '@/utils/devUtils'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { formConfig, columnsConfig } from './utils/config.js'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { financingApplyApi } from '@/api/businessApi.js'
export default {
  components: { BaseForm, BaseTable, BaseButton },
  props: {},
  data () {
    return {
      fold: false,
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1,
        AmountArr: ['', '']
      },
      tableData: []
    }
  },
  // 计算属性 类似于data概念
  computed: {
    formConfig () {
      return formConfig(this)
    },
    columns () {
      return columnsConfig(this)
    },
    api () {
      return financingApplyApi
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 保留两位小数
    keepTwoDecimalFull (num) {
      let result = parseFloat(num)
      if (isNaN(result)) {
        return '--'
      }
      result = Math.round(num * 100) / 100
      let sx = result.toString()
      let posDecimal = sx.indexOf('.')
      if (posDecimal < 0) {
        posDecimal = sx.length
        sx += '.'
      }
      while (sx.length <= posDecimal + 2) {
        sx += '0'
      }
      return sx
    },
    // 导出
    FileDdownload () {
      const params = JSON.parse(JSON.stringify(this.queryParas))
      if (params.AmountArr[0]) {
        params.lowApplyAmount = params.AmountArr[0]
      }
      if (params.AmountArr[1]) {
        params.highApplyAmount = params.AmountArr[1]
      }
      if (params.addressArr && params.addressArr.length > 0) {
        params.address = params.addressArr[0] + params.addressArr[1]
      }

      delete params.pageIndex
      delete params.pageSize
      delete params.AmountArr
      this.api.financingExport(params).then((res) => {
        if (+res.status === 200) {
          downFile(res, '融资需求申请.xlsx')
        }
      })
    },
    AmountArrChange (index, data) {
      this.$set(this.queryParas.AmountArr, index, data)
    },
    handleFilter () {
      this.queryParas = JSON.parse(JSON.stringify(this.queryParas))

      this.queryParas.lowApplyAmount = this.queryParas.AmountArr[0]

      this.queryParas.highApplyAmount = this.queryParas.AmountArr[1]
      if (this.queryParas.addressArr && this.queryParas.addressArr.length > 0) {
        this.queryParas.address =
          this.queryParas.addressArr[0] + this.queryParas.addressArr[1]
      } else {
        this.queryParas.address = ''
      }
      this.queryParas.pageIndex = 1
      this.$nextTick(() => {
        this.loadCount++
      })
    },
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.handleFilter()
  }
}
</script>
<style lang='scss' scoped>

.download {
  background: #fff;
  text-align: right;
  padding: 0 21px;
  padding-top: 20px;
  margin-top: 8px;
}
/deep/ .el-cascader {
  width: 100%;
}
</style>
